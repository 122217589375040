import { InjectionToken } from '@angular/core';
import { EmployeeApiService } from './employee-api.service';

export const HTTP_EMPLOYEE_SERVICE = new InjectionToken<EmployeeApiService>(
  'EmployeeApiService'
);

export const EMPLOYEE_PROVIDER = {
  provide: HTTP_EMPLOYEE_SERVICE,
  useClass: EmployeeApiService,
};
