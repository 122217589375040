import { inject, Injectable } from '@angular/core';
import { IEmployeeApiService } from './employee-api.interface';
import { map, Observable, take } from 'rxjs';
import { Employee } from '../domain/employee.model';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmployeeApiModel } from './employee-api.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeApiService implements IEmployeeApiService {
  private readonly BASE_URL = environment.baseUrl;
  private _http = inject(HttpClient);

  private _mapResponseToEmployeeModel(response: EmployeeApiModel): Employee {
    return {
      employeeId: response.employeeId,
      uid: response.uid,
      name: response.name,
      experience: response.experience,
      photo: response.photo,
      baseSalary: response.baseSalary,
      commission: response.commission,
      schedule: response.schedule,
      information: response.information,
      nickname: response.nickname,
      phone: response.phone,
      password: response.password,
      userId: response.userId,
      companyId: response.companyId,
      statusId: response.statusId,
      createdAt: response.createdAt,
      updatedAt: response.updatedAt,
    };
  }

  getEmployees(companyUid: string): Observable<Employee[]> {
    const url = `${this.BASE_URL}employees?c_uid=${companyUid}`;
    const headers = new HttpHeaders();
    return this._http.get<EmployeeApiModel[]>(url, { headers }).pipe(
      take(1),
      map((response) => response.map(this._mapResponseToEmployeeModel))
    );
  }

  getEmployee(uuid: string): Observable<Employee> {
    const url = `${this.BASE_URL}employees/${uuid}`;
    const headers = new HttpHeaders();
    return this._http
      .get<EmployeeApiModel>(url, { headers })
      .pipe(take(1), map(this._mapResponseToEmployeeModel));
  }

  createEmployee(employee: Employee): Observable<Employee> {
    const url = `${this.BASE_URL}employees`;
    const headers = new HttpHeaders();
    return this._http
      .post<EmployeeApiModel>(url, employee, { headers })
      .pipe(take(1), map(this._mapResponseToEmployeeModel));
  }

  updateEmployee(uuid: string, employee: Employee): Observable<Employee> {
    const url = `${this.BASE_URL}employees/${uuid}`;
    const headers = new HttpHeaders();
    return this._http
      .put<EmployeeApiModel>(url, employee, { headers })
      .pipe(take(1), map(this._mapResponseToEmployeeModel));
  }

  deleteEmployee(uuid: string): Observable<void> {
    const url = `${this.BASE_URL}employees/${uuid}`;
    const headers = new HttpHeaders();
    return this._http.delete<void>(url, { headers });
  }
}
